import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, FormControl, ListItemText, Menu, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import {
    DriveEtaOutlined,
    HotelOutlined,
    TransferWithinAStationOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import { AppState } from "../../Reducers/Reducers";
import { Traveler } from "../../Reducers/objects/Traveler";
import { AnyAaaaRecord } from "node:dns";

type FlightQuestionAnswerProps = {
    question: any,
    travelers_list: Traveler[],
    setFlightQuestions: any,
    flight_id: number,
    flightQuestions: AnyAaaaRecord,
    question_index: number
}

export function FlightQuestionAnswer(props: FlightQuestionAnswerProps): JSX.Element  {
    const { question, travelers_list, setFlightQuestions, flight_id, flightQuestions, question_index } = props;
    const { t } = useTranslation();

    const handleChange = (e: any, traveler_index: number) => {
        let flight_questions = {...flightQuestions};
        flight_questions[flight_id][question_index].user_answers[traveler_index] = e.target.value;
        setFlightQuestions(flight_questions);
    }
    const handleChangeArray = (event: any, traveler_index: number) => {
        const {
            target: { value },
        } = event;
        let flight_questions = {...flightQuestions};
        flight_questions[flight_id][question_index].user_answers[traveler_index] = typeof value === 'string' ? value.split(',') : value;
        setFlightQuestions(flight_questions);
    }
    return (
        <Stack
            direction={"row"}
            spacing={1.5}
            alignItems={!question.per_passenger ? "center" : "flex-start"}
            justifyContent={'flex-end'}
        >
            <Stack direction={'column'}>
                <Typography
                    variant="body1"
                    color="textSecondary"
                >
                    {question.display_text}{!question.is_optional && ' *'}
                </Typography>
                {
                    question.per_passenger &&
                    <Typography variant="caption" color="textSecondary">({t("shared.per-person")})</Typography>
                }

            </Stack>
            <Stack
                direction="column"
                spacing={1.5}
            >
                {
                    question.answer_choices.length === 0 &&
                    <>
                    {
                        question.per_passenger &&
                        travelers_list.map((traveler, traveler_index) => (
                            <FormControl fullWidth variant={ 'outlined' } key={traveler_index} size={ 'small' } sx={{ width: 197 }}>
                                <OutlinedInput
                                    id={ `poi-question-${question.id}` }
                                    type={ 'text' }
                                    value={question.user_answers[traveler_index] ?? ''}
                                    onChange={(e) => handleChange(e, traveler_index)}
                                />
                            </FormControl>
                        ))
                    }
                    {
                        !question.per_passenger &&
                        <FormControl fullWidth variant={ 'outlined' } size={ 'small' } sx={{ width: 197 }}>
                            <OutlinedInput
                                id={ `poi-question-${question.id}` }
                                type={ 'text' }
                                value={question.user_answers[0] ?? ''}
                                onChange={(e) => handleChange(e, 0)}
                            />
                        </FormControl>
                    }
                    </>
                }
                {
                    question.answer_choices.length > 0 &&
                    <>
                    {
                        !question.per_passenger &&
                            <FormControl fullWidth variant={ 'outlined' } sx={{ width: 197 }} size={ 'small' }>
                                <Select
                                    onChange={(e) => handleChangeArray(e, 0)}
                                    value={question.user_answers ?? []}
                                    multiple={question.answer_type !== 'STRING'}
                                    renderValue={(selected) => selected.join(', ')}
                                    input={
                                        <Tooltip title={question.user_answers.join(', ')} placement={"right"} arrow>
                                            <OutlinedInput />
                                        </Tooltip>
                                    }
                                >
                                    {
                                        question.answer_choices.map((choice:any, choice_index:number) => (
                                            <MenuItem value={choice} key={choice_index}>
                                                <Checkbox checked={question.user_answers.includes(choice)} />
                                                <ListItemText primary={choice} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                    }
                    {
                        question.per_passenger &&
                        travelers_list.map((traveler, traveler_index) => (
                            <FormControl fullWidth variant={ 'outlined' } key={traveler_index} size={ 'small' } sx={{ width: 197 }}>
                                <Select
                                    onChange={(e) => handleChangeArray(e, traveler_index)}
                                    value={question.user_answers[traveler_index] ?? []}
                                    multiple={question.answer_type !== 'STRING'}
                                    renderValue={(selected) => selected.join(', ')}
                                    input={
                                        question.user_answers[traveler_index] !== undefined ?
                                            <Tooltip title={question.user_answers[traveler_index].join(', ')} placement={"right"} arrow>
                                                <OutlinedInput />
                                            </Tooltip> :
                                            <OutlinedInput />

                                    }
                                >
                                    {
                                        question.answer_choices.map((choice:any, choice_index:number) => (
                                            <MenuItem value={choice} key={choice_index}>
                                                <Checkbox checked={question.user_answers[traveler_index] !== undefined && question.user_answers[traveler_index].includes(choice)} />
                                                <ListItemText primary={choice} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        ))
                    }
                    </>
                }
            </Stack>
        </Stack>
    );
}
